import React from 'react';
import styled from 'styled-components';
import {useI18next, Link, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import {PricingPlan} from 'components/Pricing/PricingPlanInterface';
import {AnchorLink} from 'components/AnchorLink';

import AsSeenOn from 'components/Alternatives/AsSeenOn';
import WhyUse from 'components/Alternatives/WhyUse';
import CheckCells from 'components/Alternatives/CheckCells';
import {
  ComparisonBlock,
  ComparisonTitle,
  PriceCompareTable,
  FeatureCompareTable,
  ComparisonHeadline,
  HeadingRow
} from 'components/Alternatives/ComparisonComponents';
import {
  Features,
  FeatureImage,
  FeatureList,
  FeatureItem,
  FeatureContent
} from 'components/Alternatives/FeatureComponents';
import CallToAction from 'components/Alternatives/CallToAction';
import MoonmailButton from 'components/Button';

import EmailMarketingSoftwareImage from 'images/email-marketing-software.svg';
import MoonmailLogo from 'images/moonmail-logo-full.svg';
import Mailchimp from 'images/mailchimp.svg';

import FasterEmail from 'images/faster-email.svg';
import Cleaning from 'images/cleaning.svg';
import UnlimitedSubs from 'images/unlimited-subs.svg';
import SimplePricing from 'images/simple-pricing.svg';
import PersonalizationTags from 'images/personalization-tags.svg';
import MultilingualChat from 'images/multilingual-chat.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  const alternative = 'Mailchimp';
  return (
    <>
      <Helmet title={t('{{alternative}} alternative', {alternative})}>
        <meta
          name="description"
          content={t(
            `Looking for a {{alternative}} Alternative? MoonMail is your choice if you're looking for the email marketing platform for the professional marketer. Click here to learn more!`,
            {alternative}
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={EmailMarketingSoftwareImage}
                alt={t('Email marketing software')}
                title={t('OmniChannel Communication Platform')}
                width={296}
                height={201}
              />
              <h1>{t('Looking for a {{alternative}} alternative?', {alternative})}</h1>
              <IndexSectionSummary>
                {t('MoonMail is your choice if you’re looking for simple email marketing.')}
              </IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to fly to the moon')}>
                  {t('Switch to MoonMail now')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <AsSeenOn />
        </Stars>
        <section>
          <Container>
            <WhyUse name="{{alternative}}">
              <Trans>
                Email marketing is still one of the most-used strategies in digital marketing. With
                so many tools out there, it’s hard to determine which one fits your needs. At first,
                {{alternative}} made email marketing available for people without technical skills.
                However, as it grew larger, its prices rose. This made the service less accessible,
                unless you could afford to pay more.
              </Trans>
            </WhyUse>
          </Container>
          <ComparisonTitle>{t('Pricing Comparison per One Month')}</ComparisonTitle>
          <ComparisonBlock>
            <PriceCompareTable>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <img
                      src={MoonmailLogo}
                      alt={t('MoonMail vs {{alternative}}', {alternative})}
                      title={t('MoonMail vs {{alternative}}', {alternative})}
                    />
                  </th>
                  <th>
                    <img
                      src={Mailchimp}
                      alt={t('{{alternative}} alternative', {alternative})}
                      title={t('{{alternative}} alternative', {alternative})}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <Trans>
                  <tr>
                    <td>Send 1 campaign to 10,000 subscribers</td>
                    <td>$14.99</td>
                    <td>$75</td>
                  </tr>
                  <tr>
                    <td>Send 1 campaign to 100,000 subscribers</td>
                    <td>$59.99</td>
                    <td>$475</td>
                  </tr>
                  <tr>
                    <td>Send 1 campaign to 1,000,000 subscribers</td>
                    <td>$509.99</td>
                    <td>$4,200</td>
                  </tr>
                </Trans>
              </tbody>
            </PriceCompareTable>
          </ComparisonBlock>
          <ComparisonHeadline>
            <Trans>
              <p>
                {{alternative}} has subscribers limitations and email limitations in the higher
                plans. If you exceed their sending quota, you could be charged a fee that equals
                nearly one entire month extra.
              </p>
              <p>
                MoonMail’s monthly subscription is fixed at $19.99 + starting at $0.05 for every
                1,000 emails sent. With unlimited subscribers and unlimited emails. You pay for what
                you use. As simple as that.
              </p>
            </Trans>
          </ComparisonHeadline>
          <ComparisonTitle>{t('Feature Summary')}</ComparisonTitle>
          <ComparisonBlock>
            <FeatureCompareTable>
              <tr>
                <th></th>
                <th>
                  <img
                    src={MoonmailLogo}
                    alt={t('MoonMail vs {{alternative}}', {alternative})}
                    title={t('MoonMail vs {{alternative}}', {alternative})}
                  />
                </th>
                <th>
                  <img
                    src={Mailchimp}
                    alt={t('{{alternative}} alternative', {alternative})}
                    title={t('{{alternative}} alternative', {alternative})}
                  />
                </th>
              </tr>
              <tbody>
                <tr>
                  <td>{t('Unlimited Subscribers')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Unlimited emails/month')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Drag and drop editor')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Automations')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('List Segmentation')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Unlimited Custom Personalization tags')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Liquid markup syntax')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Responsive emails')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Click map')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Real-time statistics')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Double opt-in')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td> {t('Webforms')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Steps to sign up')}</td>
                  <td>2</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>{t('Multilingual Interface')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Mulitilingual Live Chat Support')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Free Support to all Users')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Fully GDPR compliant')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Link to="/email-marketing-affiliate-program/">
                      {t('Make money with the platform')}
                    </Link>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <HeadingRow>
                  <td>{t('For the techy ones...')}</td>
                  <td></td>
                  <td></td>
                </HeadingRow>
                <tr>
                  <td>{t('Choose double opt in or not in your web forms')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Attach files*')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('RSS to email*')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Free schema.org markup assessment')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Fully Open Source Project')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Trans>
                      Deploy the whole software into your own Amazon
                      <br />
                      Web Services Account
                    </Trans>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Use your own dedicated IP')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
              </tbody>
            </FeatureCompareTable>
          </ComparisonBlock>
          <CallToAction>
            <ComparisonTitle>{t('Switch now from {{alternative}} to MoonMail!')}</ComparisonTitle>
            <MoonmailButton href={links.signUp} title={t('Try MoonMail for Free')}>
              {t('Signup for Free')}
            </MoonmailButton>
          </CallToAction>
        </section>
        <Features>
          <Container>
            <FeatureList>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={UnlimitedSubs}
                    alt={t('The best {{alternative}} Alternative has Unlimited Subscribers', {
                      alternative
                    })}
                    title={t('The best {{alternative}} Alternative has Unlimited Subscribers', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Unlimited Subscribers')}</ComparisonTitle>
                  <p>
                    <Trans>
                      You can store as many subscribers’ emails as you want. While {{alternative}}
                      automatically upgrades you to a higher billing plan if you exceed your free
                      subscriber limits, we will never automatically upgrade your billing plan.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={SimplePricing}
                    alt={t('The best {{alternative}} Alternative has simple pricing', {
                      alternative
                    })}
                    title={t('The best {{alternative}} Alternative has simple pricing', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>
                    {t('Simple and flexible pricing: Pay for what you send')}
                  </ComparisonTitle>
                  <p>
                    <Trans>
                      Compared to {{alternative}}, MoonMail doesn’t tie you to strict limitations on
                      how many subscribers you can have. Simply put, with MoonMail you can have up
                      to 1 Milion subscribers as you want and your subscription will be the same.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={FasterEmail}
                    alt={t('The best {{alternative}} Alternative is fast')}
                    title={t('The best {{alternative}} Alternative is fast')}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Sending email faster than ever')}</ComparisonTitle>
                  <p>
                    <Trans>
                      For signup, you need to register with a{' '}
                      <AnchorLink
                        href="https://support-legacy.moonmail.ioaccounts-and-validation/accounts/can-i-sign-in-without-google-account"
                        target="_blank"
                        external>
                        Google account.
                      </AnchorLink>{' '}
                      With one click, you verify your phone and fill your company information, and
                      that’s it. After sign-up with MoonMail, just import your list of subscribers,
                      create a campaign, and send it. Skip all the unnecessary questions and start
                      sending emails without verifying your sender email address.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={PersonalizationTags}
                    alt={t('The best {{alternative}} Alternative uses liquid syntax')}
                    title={t('The best {{alternative}} Alternative uses liquid syntax')}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Custom Fields and Liquid Syntax')}</ComparisonTitle>
                  <p>
                    <Trans>
                      Both MoonMail and {{alternative}} have have Custom Fields or Personalization
                      Tags. However, with MoonMail you can both existing Custom Fields and create
                      you own{' '}
                      <AnchorLink
                        href="https://support-legacy.moonmail.iothe-platformFeatureItemsts-and-subscribers/how-do-i-add-custom-fields-to-my-lists"
                        target="_blank"
                        external>
                        Custom Fields
                      </AnchorLink>
                      , without limitation, to match your needs.
                    </Trans>
                  </p>
                  <p>
                    <Trans>
                      We use Liquid syntax that helps you to create complex interactions, such as
                      dynamic content, by adding simple rules.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={MultilingualChat}
                    alt={t(
                      'The best {{alternative}} Alternative has Multilingual Live Chat Support',
                      {alternative}
                    )}
                    title={t(
                      'The best {{alternative}} Alternative has Multilingual Live Chat Support',
                      {alternative}
                    )}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Multilingual Live Chat Support')}</ComparisonTitle>
                  <p>
                    <Trans>
                      At MoonMail, we have a multilingual team available to support you if you need
                      help or just want to say hi. {{alternative}}, in comparison, sends you through
                      forums, forms, and many questions before ever having a personal conversation.
                      You don’t need to have an emergency to contact the MoonMail team, which
                      supports (today) 10 languages.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
            </FeatureList>
            <CallToAction>
              <ComparisonTitle>
                {t(
                  'Willing to have a dedicated Account Manager help you with your {{alternative}} to MoonMail migration?',
                  {alternative}
                )}
              </ComparisonTitle>
              <MoonmailButton href={links.signUp} title={t('Migrate now for Free')}>
                {t('Migrate now for Free')}
              </MoonmailButton>
            </CallToAction>
          </Container>
        </Features>
      </div>
    </>
  );
};

export default Page;
